<template>
  <div>
    <div v-if="isEdit">
      <div class="vx-row">
        <div :class="colClass">
          <vs-input @input="(val) => (rfc = rfc.toUpperCase())" class="w-full" label="RFC *" name="rfc"
            v-validate="requiredRules" v-model.lazy="rfc" :danger="hasError('rfc')" :danger-text="errorText('rfc')"
            :success="isSuccess('rfc')" icon-pack="feather" :placeholder="requiredPlaceholder" />
          <a v-if="!isMoral" class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(rfcUrl)">Consultar mi RFC</a>
        </div>
        <div :class="colClass">
          <vs-input @input="(val) => (taxName = taxName.toUpperCase())" class="w-full" label="Nombre fiscal *" name="taxName"
            v-validate="requiredRules" v-model.lazy="taxName" :danger="hasError('taxName')" :danger-text="errorText('taxName')"
            :success="isSuccess('taxName')" icon-pack="feather" :placeholder="requiredPlaceholder" />
        </div>
      </div>
      <div class="vx-row">
        <div :class="colClass">
          <vs-input class="w-full" label="Código postal fiscal *" name="taxZipCode" v-validate="'required|numeric'"
            v-model.lazy="taxZipCode" :danger="hasError('taxZipCode')" :danger-text="errorText('taxZipCode')"
            :success="isSuccess('taxZipCode')" icon-pack="feather" :placeholder="requiredPlaceholder" />
        </div>
        <div :class="colClass">
          <vs-select
              placeholder="Selecciona una opción"
              class="w-full"
              label="Régimen Fiscal *"
              v-model.lazy="taxRegimeId"
              name="taxRegimeId"
              v-validate="requiredRules"
              @input="setCfdis()"
              :danger="hasError('taxRegimeId')"
              :danger-text="errorText('taxRegimeId')"
              :success="isSuccess('taxRegimeId')">
                <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="(item,index) in collections.taxRegimesFiltered" />
            </vs-select>
        </div>
      </div>
      <div class="vx-row">
        <div :class="colClass">
          <vs-select
            placeholder="Selecciona una opción"
            class="w-full"
            label="Uso de CFDI *"
            v-model.lazy="cfdiId"
            name="cfdiId"
            v-validate="requiredRules"
            :danger="hasError('cfdiId')"
            :danger-text="errorText('cfdiId')"
            :success="isSuccess('cfdiId')">
              <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="(item,index) in collections.cfdisFiltered" />
          </vs-select>
        </div>
          <div v-if="isMoral" :class="colClass">
            <vs-select
              :searchable="true"
              placeholder="Selecciona una opción"
              class="w-full"
              label="Régimen Capital *"
              v-model.lazy="capitalRegimeId"
              name="capitalRegimeId"
              v-validate="requiredRules"
              :danger="hasError('capitalRegimeId')"
              :danger-text="errorText('capitalRegimeId')"
              :success="isSuccess('capitalRegimeId')">
                <vs-select-item value="0" text="Sin régimen capital"/>
                <vs-select-item v-if="rfc != defaultRFC" :key="index" :value="item.id" :text="`${item.name}`" v-for="(item, index) in collections.allBusinessCapitalRegimes" />
              </vs-select>
          </div>
          <div v-if="isMoral && efirmaRequired" :class="colClass">
            <vs-input @input="(val) => (efirmaNumber = efirmaNumber.toUpperCase())" class="w-full" label="Número de serie de la Firma Electrónica Avanzada *" name="efirmaNumber"
            v-validate="requiredRules" v-model.lazy="efirmaNumber" :danger="hasError('efirmaNumber')" :danger-text="errorText('efirmaNumber')"
            :success="isSuccess('efirmaNumber')" icon-pack="feather" :placeholder="requiredPlaceholder" />
            <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(efirmaUrl)">Consultar efirma</a>

          </div>
      </div>
      <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
        <span class="font-regular">{{ errorMssg }}</span>
      </vs-alert>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="bold" v-if="fiscalValidationTimestamp">
            Los datos ya fueron verficados el: {{ explicitDateFormat(fiscalValidationTimestamp) }}<br>
            Si deseas actualizarlos nuevamente contactanos por el chat.
          </p>
          <vs-button v-else-if="showButton" :id="saveBtnId" @click="saveDataForm">Guardar cambios</vs-button>
        </div>
      </div>
    </div>
    <div class="list-info" v-else>
      <div class="vx-row mb-4">
        <div class="vx-col w-ful">
          <ul class="tax-profile-list">
            <li>
              <strong>RFC:</strong> {{ rfc }}
            </li>
            <li>
              <strong>Nombre fiscal:</strong> {{ taxName.toUpperCase() }}
            </li>
            <li>
              <strong>Código postal fiscal:</strong> {{ taxZipCode }}
            </li>
            <li>
              <strong>Régimen fiscal:</strong> {{ taxRegimeNamed }}
            </li>
            <li>
              <strong>Uso de CFDI:</strong> {{ cfdiNamed }}
            </li>
            <li v-if="isMoral">
              <strong>Regimen capital:</strong> {{ getCapitalRegime("name") }}
            </li>
            <li v-if="isMoral && efirmaRequired">
              <strong>Número de serie de la Firma Electrónica Avanzada:</strong> {{ efirmaNumber }}
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="bold" v-if="fiscalValidationTimestamp && showButton">
            Los datos del <strong>perfil fiscal</strong> ya fueron verficados el: {{ explicitDateFormat(fiscalValidationTimestamp) }}<br>
            Si deseas actualizarlos nuevamente contactanos por el chat.
          </p>
          <vs-button class="mt-5" v-if="showButton" :id="saveBtnId" @click="saveDataForm">Guardar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import dateHelper from "@mixins/dateHelper";

const defaultRFC = "XAXX010101000";

export default {
  name: "UserTaxProfile",
  mixins: [inputHelper, formatHelper, dateHelper],
  props: {
    // determina si se muestra el boton de guardado
    showButton: {
      type: Boolean, required: false, default: true
    },
    // determina si la informacion puede ser editada o no
    isEdit: {
      type: Boolean, required: false, default: true
    },
    // se debe setear a true cuando se requiera el numero de efirma (solo aplica para PM)
    efirmaRequired: {
      type: Boolean, required: false, default: false
    },
    // se debe setear en caso de querer saber el rastreo del boton de guardado
    saveBtnId: {
      type: String, required: false, default: "user_tax_profile_confirm_btn"
    },
  },
  data() {
    return {
      userPersonTypePF: 1,
      userPersonTypePM: 0,
      collections: {
        allTaxRegimes: [],
        allCfdis: [],
        cfdisFiltered: [],
        taxRegimesFiltered: [],
        allBusinessCapitalRegimes: [],
        businessCapitalRegimes: []
      },
      rfcUrl:
        "https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp",
      efirmaUrl: "https://portalsat.plataforma.sat.gob.mx/RecuperacionDeCertificados/",
      requiredRules: "required",
      colClass: "vx-col lg:w-1/2 sm:w-full w-full mb-5",
      errorMssg: null,
      requiredPlaceholder: "(Requerido)",
      userPersonType: null,
      rfc: null,
      taxName: '',
      taxZipCode: '',
      cfdiId: null,
      taxRegimeId: null,
      cfdiNamed: null,
      taxRegimeNamed: null,
      fiscalValidationTimestamp: null,
      capitalRegimeId: null,
      defaultRFC: defaultRFC,
      efirmaNumber:''
    };
  },
  async beforeMount() {
    this.showLoading(true);
    await this.getUserTaxProfileData();
    if (!this.isEdit) {
      this.getCfdis();
    }
    this.showLoading(false);
  },
  computed: {
    isMoral() {
      return this.userPersonType == this.userPersonTypePM;
    }
  },
  methods: {
    async getCollections() {
      try {
        let collectionsObjects = ["cfdis", "taxRegimes", "capitalRegimes"];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await this.publicApiGet(
          `/api/v1/forms/getPublicFormCollections?${params}`
        );
        let colls = res.data;
        this.collections.allCfdis = colls.cfdis;
        this.collections.allTaxRegimes = colls.taxRegimes;
        this.collections.allBusinessCapitalRegimes = colls.capitalRegimes;
        let taxRegimesForPersonType = null;
        if (this.userPersonType == this.userPersonTypePF) {
          taxRegimesForPersonType = colls.taxRegimes.filter(
            (taxRegime) => taxRegime.for_person
          );
        } else if (this.userPersonType == this.userPersonTypePM) {
          taxRegimesForPersonType = colls.taxRegimes.filter(
            (taxRegime) => taxRegime.for_business
          );
        }
        taxRegimesForPersonType.forEach((opt) => {
          this.collections.taxRegimesFiltered.push({
            value: opt.id,
            label: opt.sat_code + "-" + opt.description,
          }); //conversion a sintaxis de vx-select
        });
      } catch (e) { }
    },
    async getUserTaxProfileData() {
      try {
        const response = await axios.get(`/api/v1/user/${this.UserId}/getTaxProfile`);
        this.userPersonType = response.data.person_type;
        this.rfc = response.data.rfc;
        this.taxName = response.data.tax_name;
        this.taxZipCode = response.data.tax_zipcode;
        this.taxRegimeId = response.data.tax_regime_id;
        this.fiscalValidationTimestamp = response.data.fiscal_validation_timestamp;

        // Sólo si es PM se obtiene el regimen capital y la efirma
        if (this.isMoral) {
          this.capitalRegimeId = response.data.capital_regime_id;
          this.efirmaNumber = response.data.efirma_number;
        }
        
        if (this.fiscalValidationTimestamp != null && this.rfc === this.defaultRFC ) {
          this.capitalRegimeId = 0;
        }
        await this.getCollections();
        this.setCfdis();
        this.cfdiId = response.data.cfdi_id;
      }
      catch (e) {
        this.error_message = 'Ocurrió  un error al obtener los datos, inténtalo mas tarde.'
      }
    },
    async saveDataForm() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      let validRfc = this.isValidRfcFormat(this.rfc);
      if (!validRfc) {
        this.errorMssg = "La estructura de la clave RFC es incorrecta."
        setTimeout(() => this.errorMssg = null, 5000);
        return;
      }
      this.showLoading(true, "Guardando información...");
      try {
        let payload = {
          rfc: this.rfc,
          cfdi_id: this.cfdiId,
          tax_regime_id: this.taxRegimeId,
          tax_zipcode: this.taxZipCode,
          tax_name: this.taxName,
          capital_regime_id: this.capitalRegimeId,
          efirma_required: this.efirmaRequired,
          efirma_number: this.efirmaNumber,

        };
        await axios.post(`/api/v1/user/${this.UserId}/saveTaxProfile`, payload);
        this.showLoading(false);
        this.$emit('on-form-saved', 1)
        if (this.showButton) {
          this.saveSuccessNotif();
        } else {
          this.saveSuccessNotif("Perfil fiscal verificado.", "Si deseas actualizarlos nuevamente contactanos por el chat.");
        }
        await this.getUserTaxProfileData();
        if(!this.showButton) {
          return true;
        }
      } catch (e) {
        if(e && e.response && e.response.data && e.response.data.error){
          this.showLoading(false);
          this.errorNotifDialog('Aviso', e.response.data.error);
        }
        else {
          this.showLoading(false);
          this.errorNotifDialog('Aviso', 'No ha sido posible obtener la información, intente más tarde.');
        }
      }
    },
    async validateForm() {
      this.errorMssg = null;
      let complete = false;
      if (this.$validator.validateAll()) {
        if (this.cfdiId != null && this.taxRegimeId != null && this.rfc != null && this.taxZipCode != null && this.taxName != null 
        && this.rfc != '' && this.taxZipCode != '' && this.taxName != '') {
          if (this.isMoral) {
            if (this.capitalRegimeId != null) {
              complete = true;
              if (this.efirmaRequired && (this.efirmaNumber == null || this.efirmaNumber == '')) {
                complete = false;
              }
            }
          } else {
            complete = true;
          }
        }
      }
      if (!complete) {
        this.errorMssg = "Debes completar todos los campos";
        setTimeout(() => this.errorMssg = null, 5000);
      }
      console.log(complete);
      return complete;
    },
    setCfdis(){
      this.cfdiId = null;
      let result = this.collections.allTaxRegimes.find(taxRegime => taxRegime.id === this.taxRegimeId);
      let taxRegimeSatCode = result? result.sat_code : null;
      if (!taxRegimeSatCode) {
        return;
      }
      this.collections.cfdisFiltered = [];
      this.collections.allCfdis.forEach((cfdi) => {
        if (cfdi.recipient_tax_regime.includes(taxRegimeSatCode)) {
          this.collections.cfdisFiltered.push({
            value: cfdi.id,
            label: cfdi.sat_code + "-" + cfdi.description,
          }); //conversion a sintaxis de vx-select             
        }
      });
    },
    /**
     * Busca en las colecciones el regimen fiscal y su cfdi.
     */
    getCfdis(){
      try {
        this.cfdiNamed = null;
        this.taxRegimeNamed = null;
        const result = this.collections.allTaxRegimes.find(taxRegime => taxRegime.id === this.taxRegimeId);
        this.taxRegimeNamed = `${result.sat_code} - ${result.description}`;
        this.collections.allCfdis.every((cfdi) => {
          if (cfdi.id == this.cfdiId) {
            this.cfdiNamed = `${cfdi.sat_code} - ${cfdi.description}`; 
            return true;           
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    getCapitalRegime(prop) {
      try {
        const result = this.collections.allBusinessCapitalRegimes.find(capitalRegime => capitalRegime.id === this.capitalRegimeId);
        return result[prop];
      } catch (error) {
        console.log(error);
      }
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>

<style>
.tax-profile-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.list-info {
  padding: 0 2rem 0 2rem;
}
</style>